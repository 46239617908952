import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import Button from '../components/Button';
import Layout, { Section } from '../components/Layout';
import { Seo } from '../components/Seo';
import { Text, Title } from '../components/Text';
import { sprinkles } from '../sprinkles.css';

const ImprintPage = () => {
	const { t } = useTranslation('ImprintPage');
	return (
		<Layout>
			<Section gap="large" width="wide">
				<Title level="section">{t('Title')}</Title>
				<Text>{t('Description')}</Text>
				<Section direction="horizontal" align="start" gap="large" width="wide" wrap>
					<Section
						direction="vertical"
						justify="center"
						gap="large"
						className={sprinkles({
							flexBasis: 'four',
							flexGrow: '1',
							width: {
								mobile: 'fill',
								tablet: 'fill',
								desktopHalf: 'fourtyFive',
							},
						})}
					>
						<Title level="subsection">konfidal GmbH</Title>
						<Section gap="none" align="start">
							<Text>Hauptstr. 28</Text>
							<Text>15806 Zossen</Text>
							<Text>{t('AddressCountry')}</Text>
						</Section>
						<Section gap="none" align="start">
							<Text>{`${t('Email')}: hi@konfidal.eu`}</Text>
							<Text>{`${t('Phone')}: +49 (0) 176 72224558`}</Text>
							<Text>{`${t('RegistrationCourt')}: Amtsgericht Potsdam`}</Text>
							<Text>{`${t('RegisterNumber')}: 36677 P`}</Text>
							<Text>{`${t('VatId')}: DE355946845`}</Text>
							<Text>{`${t('Representatives')}: Frederik Wegner, Martin Meng`}</Text>
						</Section>
					</Section>
					<Section
						direction="vertical"
						justify="center"
						gap="large"
						className={sprinkles({
							flexBasis: 'four',
							flexGrow: '1',
							width: {
								mobile: 'fill',
								tablet: 'fill',
								desktopHalf: 'fill',
							},
						})}
					>
						<Title level="subsection">{t('DisputeResolutionReferenceTitle')}</Title>
						<Section>
							<Text variant="secondary">{t('DisputeResolutionReferenceTextIntro')}</Text>
							<Button
								onClick={() =>
									window.open('https://ec.europa.eu/consumers/odr', '_blank')?.focus()
								}
								variant="secondaryText"
							>
								ec.europa.eu/consumers/odr
							</Button>
						</Section>
						<Text variant="secondary">
							{t('DisputeResolutionReferenceTextStatement')}
						</Text>
					</Section>
				</Section>
			</Section>
		</Layout>
	);
};

export default ImprintPage;

export const Head = () => {
	return <Seo />;
};

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
